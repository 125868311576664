import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './guides.scss';
import { Card } from 'react-bootstrap';
import { ZZZCharacter } from '../../../modules/zzz/common/components/zzz-character';

const HSRGuidesGachaPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gacha System</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_gacha.png"
            alt="Gacha system"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero Gacha System</h1>
          <h2>Information about the gacha system in Zenless Zone Zero.</h2>
          <p>
            Last updated: <strong>28/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Gacha System" />
        <p>
          Zenless Zone Zero uses a gacha system as the primary way for a player
          to obtain new Characters, W-Engines and Bangboos and it is generally
          the only way to obtain the highest rarity Characters, W-Engines and
          Bangboos.
        </p>
        <SectionHeader title="Signal Search" />
        <p>
          Signal Search is what Zenless Zone Zero calls its gacha banners. To
          roll on the gacha, you will need to use either Polychromes, the gacha
          currency, or Master Tapes/Encrypted Master Tapes which are the gacha
          tickets. For Bangboo banners you will need{' '}
          <strong>
            Boopons, which is a currency only used for them and can't be
            obtained by spending real money.
          </strong>
        </p>
        <ul>
          <li>
            You can exchange 160 Polychromes for 1 Master Tape or Encrypted
            Master Tape.
          </li>
          <li>
            <strong>Master Tapes</strong> are used in Stable Channel Signal
            Searches and Beginner Signal Search.
          </li>
          <li>
            <strong>Encrypted Master Tapes</strong> are used for Exclusive
            Channel Signal Searches or W-Engine Channel Signal Searches.
          </li>
        </ul>
        <p>
          <strong>Polychromes, Master Tapes, and Encrypted Master Tapes</strong>{' '}
          can be obtained from various in-game sources such as missions, events,
          and certain npcs. <strong>Boopons</strong> can be obtained from
          missions, events and various modes in Hollow Deep Dive Zone.
        </p>
        <p>You can obtain the following from signal searching:</p>
        <ul>
          <li>
            <strong className="b-rank">B-rank</strong> W-Engines,
          </li>
          <li>
            <strong className="a-rank">A-rank</strong> W-Engines,
          </li>
          <li>
            <strong className="s-rank">S-rank</strong> W-Engines,
          </li>
          <li>
            <strong className="a-rank">A-rank</strong> Characters,
          </li>
          <li>
            <strong className="s-rank">S-rank</strong> Characters,
          </li>
          <li>
            <strong className="a-rank">A-rank</strong> Bangboos,
          </li>
          <li>
            <strong className="s-rank">S-rank</strong> Bangboos.
          </li>
        </ul>
        <p>
          Ranks denote the rarity of the Character, W-Engine or Bangboo. The
          higher rank they have, the higher the rarity (and the more difficult)
          it is to obtain.
        </p>
        <p>
          <strong className="a-rank">A-rank</strong> Guarantee: If a player does
          not obtain an <strong className="a-rank">A-rank</strong> Character,
          W-Engine or Bangboo after 9 signal searches, the 10th signal search is
          guaranteed to be an <strong className="a-rank">A-rank</strong>
          Character, W-Engine or Bangboo. This counter reset every time a player
          obtains an <strong className="a-rank">A-rank</strong> Character,
          W-Engine or Bangboo.
        </p>
        <SectionHeader title="Residual Signals and En-Nies" />
        <p>
          <strong>Residual Signals and En-Nies</strong> are in-game currencies
          that are only obtainable by signal searching. They can be used in the
          Signal Shop to purchase Master Tapes, Encrypted Master Tapes, Boopons,
          upgrade materials, and other resources.
        </p>
        <ul>
          <li>
            Obtaining any <strong className="a-rank">A-rank</strong> W-Engine:
          </li>
          <ul>
            <li>10 Residue Signals</li>
          </ul>
          <li>
            Obtaining any <strong className="s-rank">S-rank</strong> W-Engine:
          </li>
          <ul>
            <li>50 Residue Signals</li>
          </ul>
          <li>
            Obtaining a duplicate <strong className="a-rank">A-rank</strong>{' '}
            Character:
          </li>
          <ul>
            <li>
              1st to 6th duplicate: 10 Residue Signals and 1 Agent Profile for
              the Character
            </li>
            <li>7th duplicate onwards: 15 Residual Signals</li>
          </ul>
          <li>
            Obtaining a duplicate <strong className="s-rank">S-rank</strong>{' '}
            Character:
          </li>
          <ul>
            <li>
              1st to 6th duplicate: 50 Residual Signals and 1 Agent Profile for
              the Character
            </li>
            <li>7th duplicate onwards: 75 Residual Signals</li>
          </ul>
          <li>
            Obtaining any <strong className="a-rank">A-rank</strong> Bangboo:
          </li>
          <ul>
            <li>10 En-Nies</li>
          </ul>
          <li>
            Obtaining any <strong className="s-rank">S-rank</strong> Bangboo:
          </li>
          <ul>
            <li>50 En-Nies</li>
          </ul>
        </ul>
        <SectionHeader title="Star-Studded Cast" />
        <StaticImage
          src="../../../images/zzz/guides/banner_generic.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            <strong>First 50 Signal Searches are discounted</strong> and you are
            guaranteed to obtain an <strong className="s-rank">S-rank</strong>{' '}
            Character within the 50 Signal Searches,
          </li>
          <li>
            You will receive a 20% discount every time you signal search for
            those first 50 pulls, so it will only cost 8 Master Tapes instead of
            10.
          </li>
        </ul>
        <h5>Additional information</h5>
        <ul>
          <li>
            The rate for pulling an <strong className="s-rank">S-rank</strong>{' '}
            Character or W-Engine from this banner is currently set at{' '}
            <strong>0.6%</strong>,
          </li>
          <li>
            The rate for pulling an <strong className="a-rank">A-rank</strong>{' '}
            Character or W-Engine from this banner is currently set at{' '}
            <strong>9.4%</strong>,
          </li>
          <li>
            You are guaranteed to obtain an{' '}
            <strong className="s-rank">S-rank</strong> Character or an{' '}
            <strong className="s-rank">S-rank</strong>
            W-Engine within 90 pulls,
          </li>
          <li>
            Once you do 300 pulls you will be able to pick one{' '}
            <strong className="s-rank">S-rank</strong> available in the banner,
          </li>
          <li>
            You can only use Master Tapes on this banner (basic summon ticket).
          </li>
        </ul>
        <p>
          Here are the <strong className="s-rank">S-rank</strong> Characters
          available in the standard pool:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="grace-howard"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="koleda"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="lycaon"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="nekomata"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="soldier-11"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="rina"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Here are the <strong className="s-rank">S-rank</strong> W-Engines
          available in the standard pool:
        </p>
        <p>Coming soon!</p>
        <SectionHeader title="Character Oriented Signal Search" />
        <StaticImage
          src="../../../images/zzz/guides/banner_limited.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            One <strong className="s-rank">S-rank</strong> Character and two{' '}
            <strong className="a-rank">A-rank</strong> Characters will be
            featured on any given Character Oriented Signal Search,
          </li>
          <li>
            The rate for pulling an <strong className="s-rank">S-rank</strong>{' '}
            Character from this banner is currently set at <strong>0.6%</strong>
            ,
          </li>
          <li>
            The rate for pulling an <strong className="a-rank">A-rank</strong>{' '}
            Character or W-Engine from this banner is currently set at{' '}
            <strong>9.4%</strong>,
          </li>
          <li>
            Once you pull an <strong className="s-rank">S-rank</strong>{' '}
            Character, you have 50% chance of it being the Rate-up one,
          </li>
          <li>
            If you lose the 50/50 then the second{' '}
            <strong className="s-rank">S-rank</strong> Character you pull will
            100% be the Rate-up one,
          </li>
          <li>
            You are guaranteed to obtain an{' '}
            <strong className="s-rank">S-rank</strong> within 90 pulls (that's
            the pity). So if you're unlucky, it will take you at most 180 pulls
            to get the rate-up character.
          </li>
          <li>
            You can only use Encrypted Master Tapes on this banner (special
            summon ticket).
          </li>
        </ul>
        <SectionHeader title="W-Engine Oriented Signal Search" />
        <StaticImage
          src="../../../images/zzz/guides/banner_limited2.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            One <strong className="s-rank">S-rank</strong> W-Engine and two{' '}
            <strong className="a-rank">A-rank</strong> W-Engines will be
            featured on any given W-Engine Oriented Warp,
          </li>
          <li>
            The rate for pulling an <strong className="s-rank">S-rank</strong>{' '}
            W-Engine from this banner is currently set at <strong>1%</strong>,
          </li>
          <li>
            The rate for pulling an <strong className="a-rank">A-rank</strong>{' '}
            W-Engine from this banner is currently set at <strong>15%</strong>,
          </li>
          <li>
            Once you pull an <strong className="s-rank">S-rank</strong>{' '}
            W-Engine, you have 75% chance of it being the Rate-up one,
          </li>
          <li>
            If you lose the roll then the second{' '}
            <strong className="s-rank">S-rank</strong> W-Engine you pull will
            100% be the Rate-up one,
          </li>
          <li>
            You are guaranteed to obtain an{' '}
            <strong className="s-rank">S-rank</strong> within 80 pulls (that's
            the pity). So if you're unlucky, it will take you at most 160 pulls
            to get the rate-up W-Engine.
          </li>
          <li>
            You can only use Encrypted Master Tapes on this banner (special
            summon ticket).
          </li>
        </ul>
        <SectionHeader title="Bangboo Oriented Signal Search" />
        <StaticImage
          src="../../../images/zzz/guides/banner_bangboo.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You can select an <strong className="s-rank">S-rank</strong> Bangboo
            as the target of your pulls,
          </li>
          <li>
            Once you pull an <strong className="s-rank">S-rank</strong> Bangboo,
            you have 100% chance to receive it,
          </li>
          <li>
            The rate for pulling an <strong className="s-rank">S-rank</strong>{' '}
            Bangboo from this banner is currently set at <strong>1%</strong>,
          </li>
          <li>
            The rate for pulling an <strong className="a-rank">A-rank</strong>{' '}
            Bangboo from this banner is currently set at <strong>15%</strong>,
          </li>
          <li>
            You are guaranteed to obtain an{' '}
            <strong className="s-rank">S-rank</strong> Bangboo within 80 pulls
            (that's the pity),
          </li>
          <li>
            You can only use <strong>Boopons</strong> on this banner (F2P
            resource).
          </li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesGachaPage;

export const Head: React.FC = () => (
  <Seo
    title="Gacha system | Zenless Zone Zero | Prydwen Institute"
    description="Information about the gacha system in Zenless Zone Zero."
  />
);
